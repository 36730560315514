import { FormattedMessage } from "react-intl";
import { OptionGroup } from "../core/options/option-group";

export const openAIOptions: OptionGroup = {
    id: 'openai',
    options: [
        {
            id: 'apiKey',
            defaultValue: "",
            displayOnSettingsScreen: "nil",
            displayAsSeparateSection: false,
            renderProps: () => ({
                type: "password",
                label: "Your OpenAI API Key",
                placeholder: "sk-************************************************",
                description: <>
                </>,
            }),
        },
    ],
}