import React from 'react';
import { TextInput, PasswordInput, Button } from "@mantine/core";
import { FormattedMessage, useIntl } from "react-intl";

export default function SecretRegisterPage() {
    const intl = useIntl();

    return (
        <form action="/chatapi/secret-register" method="post">
            <h2>
                <FormattedMessage defaultMessage={"Create an account"} />
            </h2>
            <input type="hidden" name="redirect_url" value={window.location.href} />
            <TextInput
                label={intl.formatMessage({ defaultMessage: "Email address" })}
                name="username"
                placeholder={intl.formatMessage({ defaultMessage: "Enter your email address" })}
                type="email"
                required />
            <PasswordInput
                label={intl.formatMessage({ defaultMessage: "Password" })}
                name="password"
                placeholder={intl.formatMessage({ defaultMessage: "Enter your password" })}
                minLength={6}
                maxLength={500}
                required />
            <Button fullWidth type="submit">
                <FormattedMessage defaultMessage={"Sign up"} />
            </Button>
        </form>
    );
}